<script>
import { mapGetters } from 'vuex';
import { useUISettings } from 'dashboard/composables/useUISettings';
import PageCard from './components/PageCard.vue';
import RespondLayout from './RespondLayout.vue';
import BaseRespondHeader from './RespondHeader.vue';

export default {
  components: {
    RespondLayout,
    BaseRespondHeader,
    PageCard,
  },
  setup() {
    const { uiSettings } = useUISettings();

    return {
      uiSettings,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'facebookPages/getUIFlags',
      pagesList: 'facebookPages/getPages',
    }),
    pages() {
      return this.pagesList;
    },
  },
  mounted() {
    this.fetchPages();
  },
  methods: {
    fetchPages() {
      this.$store.dispatch('facebookPages/get');
    },
  },
};
</script>

<template>
  <RespondLayout
    :is-loading="uiFlags.isFetching"
    loading-message="Checking for pages..."
    :no-records-found="!pagesList.length"
    no-records-message="No pages found. Please add a page to get started`"
  >
    <template #header>
      <BaseRespondHeader
        header-title="Facebook Pages"
        feature-name="facebook-pages"
      />
    </template>
    <template #body>
      <section class="flex min-w-0 p-6 overflow-auto gap-8">
        <div v-for="page in pages" :key="page.id">
          <PageCard
            :id="page.id"
            :page-id="page.id"
            :title="page.page_name"
            :image="page.image_path"
          />
        </div>
      </section>
    </template>
  </RespondLayout>
</template>
