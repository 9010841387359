<script>
import { mapGetters } from 'vuex';
import { useUISettings } from 'dashboard/composables/useUISettings';
import RespondLayout from './RespondLayout.vue';
import BaseRespondHeader from './RespondHeader.vue';

export default {
  components: {
    RespondLayout,
    BaseRespondHeader,
  },
  setup() {
    const { uiSettings } = useUISettings();

    return {
      uiSettings,
    };
  },
  data() {
    return {
      replyPopup: {
        isOpen: false,
        commentId: null,
        username: '',
        text: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      commentsList: 'comments/getComments',
      uiFlags: 'comments/getUIFlags',
    }),
    pages() {
      return this.pagesList;
    },
  },
  mounted() {
    this.fetchComments();
  },
  methods: {
    fetchComments() {
      this.$store.dispatch('comments/get', {
        pageId: this.$route.params.page_id,
        postId: this.$route.params.post_id,
      });
    },
    openReplyPopup(commentId) {
      const comment = this.commentsList.find(c => c.id === commentId);
      if (comment) {
        this.replyPopup.isOpen = true;
        this.replyPopup.commentId = commentId;
        this.replyPopup.username = comment.from_name;
        this.replyPopup.text = '';
      }
    },
    closeReplyPopup() {
      this.replyPopup.isOpen = false;
      this.replyPopup.commentId = null;
      this.replyPopup.username = '';
      this.replyPopup.text = '';
    },
    submitReply() {
      this.$store.dispatch('comments/createReply', {
        pageId: this.$route.params.page_id,
        postId: this.$route.params.post_id,
        commentId: this.replyPopup.commentId,
        message: this.replyPopup.text,
      });
      this.closeReplyPopup();
    },
    deleteComment(commentId) {
      this.$store.dispatch('comments/delete', {
        commentId,
        pageId: this.$route.params.page_id,
        postId: this.$route.params.post_id,
      });
    },
    deleteReply(replyId, commentId) {
      this.$store.dispatch('comments/deleteReply', {
        replyId,
        commentId,
        pageId: this.$route.params.page_id,
        postId: this.$route.params.post_id,
      });
    },
    hideComment(commentId) {
      this.$store.dispatch('comments/hide', {
        commentId,
        pageId: this.$route.params.page_id,
        postId: this.$route.params.post_id,
      });
    },
    hideReply(replyId, commentId) {
      this.$store.dispatch('comments/hideReply', {
        replyId,
        commentId,
        pageId: this.$route.params.page_id,
        postId: this.$route.params.post_id,
      });
    },
  },
};
</script>

<template>
  <RespondLayout
    :is-loading="uiFlags.isFetching"
    loading-message="Loading comments..."
    :no-records-found="!commentsList.length"
    no-records-message="No comments found"
  >
    <template #header>
      <BaseRespondHeader
        header-title="Comments"
        feature-name="facebook-page-post-comments"
        show-back-button="true"
      />
    </template>
    <template #body>
      <div class="w-full mx-auto shadow-lg rounded-lg overflow-hidden">
        <div class="max-h-[80vh] p-4 overflow-y-auto">
          <div v-for="comment in commentsList" :key="comment.id" class="mb-4">
            <div class="flex items-start space-x-4">
              <div class="flex-1 space-y-1">
                <div class="flex items-center gap-8">
                  <h3 class="text-sm font-semibold">{{ comment.from_name }}</h3>
                  <span class="text-xs text-gray-500">{{
                    comment.created_time
                  }}</span>
                </div>
                <p class="text-sm text-gray-700">{{ comment.message }}</p>
                <div class="flex items-center space-x-2 text-xs text-gray-500">
                  <button
                    class="hover:text-gray-700"
                    :disabled="uiFlags.isFetching"
                    @click="openReplyPopup(comment.id)"
                  >
                    {{ 'Reply' }}
                  </button>
                  <button
                    class="hover:text-red-500"
                    :disabled="uiFlags.isFetching"
                    @click="deleteComment(comment.id)"
                  >
                    {{ 'Delete' }}
                  </button>
                  <button
                    class="hover:text-blue-500"
                    :disabled="uiFlags.isFetching"
                    @click="hideComment(comment.id)"
                  >
                    {{ comment.hidden ? 'Unhide' : 'Hide' }}
                  </button>
                </div>
              </div>
            </div>
            <!-- Nested Replies -->
            <div
              v-if="comment.replies && comment.replies.length > 0"
              class="mt-2 ml-12 space-y-2"
            >
              <div
                v-for="reply in comment.replies"
                :key="reply.id"
                class="flex items-start space-x-4 relative"
              >
                <div class="absolute left-0 top-0 bottom-0 w-px bg-white" />
                <div class="flex-1 space-y-1">
                  <div class="flex items-center gap-8">
                    <h4 class="text-xs font-semibold">{{ reply.from_name }}</h4>
                    <span class="text-xs text-gray-500">{{
                      reply.created_time
                    }}</span>
                  </div>
                  <p class="text-xs text-gray-700">{{ reply.message }}</p>
                  <div
                    class="flex items-center space-x-2 text-xs text-gray-500"
                  >
                    <button
                      class="hover:text-red-500"
                      :disabled="uiFlags.isFetching"
                      @click="deleteReply(reply.id, comment.id)"
                    >
                      {{ 'Delete' }}
                    </button>
                    <button
                      class="hover:text-blue-500"
                      :disabled="uiFlags.isFetching"
                      @click="hideReply(reply.id, comment.id)"
                    >
                      {{ reply.hidden ? 'Unhide' : 'Hide' }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Reply Pop-up -->
        <woot-modal
          v-model:show="replyPopup.isOpen"
          :on-close="closeReplyPopup"
          :close-on-backdrop-click="false"
          class="!items-start [&>div]:!top-12 [&>div]:sticky"
        >
          <div class="rounded-lg p-6 w-full max-w-md">
            <h3 class="text-lg font-semibold mb-4">
              {{ 'Reply to ' + replyPopup.username }}
            </h3>
            <textarea
              v-model="replyPopup.text"
              class="w-full h-24 p-2 border border-gray-300 rounded-md mb-4"
              placeholder="Type your reply here..."
            />
            <div class="flex justify-end space-x-2">
              <button @click="closeReplyPopup">{{ 'Cancel' }}</button>
              <button @click="submitReply">{{ 'Submit' }}</button>
            </div>
          </div>
        </woot-modal>
      </div>
    </template>
  </RespondLayout>
</template>
