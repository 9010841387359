/* global axios */
import ApiClient from './ApiClient';

class FacebookPagesAPI extends ApiClient {
  constructor() {
    super('facebook_pages', { accountScoped: true });
  }

  get() {
    return axios.get(this.url);
  }

  getPosts(pageId) {
    return axios.get(`${this.url}/${pageId}/posts`);
  }

  getPost(pageId, postId) {
    return axios.get(`${this.url}/${pageId}/posts/${postId}`);
  }

  getPostComments(pageId, postId) {
    return axios.get(`${this.url}/${pageId}/posts/${postId}/comments`);
  }

  createReply(pageId, postId, commentId, message) {
    return axios.post(
      `${this.url}/${pageId}/posts/${postId}/comments/${commentId}/create_reply`,
      { message }
    );
  }

  hideComment(pageId, postId, commentId) {
    return axios.put(
      `${this.url}/${pageId}/posts/${postId}/comments/${commentId}/hide`
    );
  }

  deleteComment(pageId, postId, commentId) {
    return axios.delete(
      `${this.url}/${pageId}/posts/${postId}/comments/${commentId}`
    );
  }
}

export default new FacebookPagesAPI();
