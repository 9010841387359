import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import FacebookPagesAPI from '../../api/facebookPages';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getComments($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }, { pageId, postId }) => {
    commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, true);
    try {
      const response = await FacebookPagesAPI.getPostComments(pageId, postId);
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
      commit(types.default.SET_POST_COMMENTS, response.data.comments);
    } catch (error) {
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
    }
  },
  createReply: async ({ commit }, { pageId, postId, commentId, message }) => {
    commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, true);
    try {
      const response = await FacebookPagesAPI.createReply(
        pageId,
        postId,
        commentId,
        message
      );
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
      commit(types.default.ADD_POST_COMMENT_REPLY, response.data.reply);
    } catch (error) {
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
    }
  },
  hide: async ({ commit }, { pageId, postId, commentId }) => {
    commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, true);
    try {
      await FacebookPagesAPI.hideComment(pageId, postId, commentId);
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
      commit(types.default.HIDE_POST_COMMENT, commentId);
    } catch (error) {
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
    }
  },
  hideReply: async ({ commit }, { pageId, postId, commentId, replyId }) => {
    commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, true);
    try {
      await FacebookPagesAPI.hideComment(pageId, postId, replyId);
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
      commit(types.default.HIDE_POST_COMMENT_REPLY, { commentId, replyId });
    } catch (error) {
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
    }
  },
  delete: async ({ commit }, { pageId, postId, commentId }) => {
    commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, true);
    try {
      await FacebookPagesAPI.deleteComment(pageId, postId, commentId);
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
      commit(types.default.DELETE_POST_COMMENT, commentId);
    } catch (error) {
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
    }
  },
  deleteReply: async ({ commit }, { pageId, postId, commentId, replyId }) => {
    commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, true);
    try {
      await FacebookPagesAPI.deleteComment(pageId, postId, replyId);
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
      commit(types.default.DELETE_POST_COMMENT_REPLY, {
        commentId,
        replyId,
      });
    } catch (error) {
      commit(types.default.SET_POST_COMMENTS_FETCHING_STATUS, false);
    }
  },
};

export const mutations = {
  [types.default.SET_POST_COMMENTS_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_POST_COMMENTS]: MutationHelpers.set,
  [types.default.ADD_POST_COMMENT_REPLY]: ($state, data) => {
    $state.records.forEach((element, index) => {
      if (element.id === data.comment_id) {
        $state.records[index].replies.push(data);
      }
    });
  },
  [types.default.HIDE_POST_COMMENT]: ($state, commentId) => {
    $state.records.forEach((element, index) => {
      if (element.id === commentId) {
        $state.records[index].is_hidden = !element.is_hidden;
      }
    });
  },
  [types.default.HIDE_POST_COMMENT_REPLY]: ($state, { commentId, replyId }) => {
    $state.records.forEach((element, index) => {
      if (element.id === commentId) {
        element.replies.forEach((reply, replyIndex) => {
          if (reply.id === replyId) {
            $state.records[index].replies[replyIndex].is_hidden =
              !reply.is_hidden;
          }
        });
      }
    });
  },
  [types.default.DELETE_POST_COMMENT]: MutationHelpers.destroy,
  [types.default.CLEAR_POST_COMMENTS]: $state => {
    $state.records = [];
  },
  [types.default.DELETE_POST_COMMENT_REPLY]: (
    $state,
    { commentId, replyId }
  ) => {
    $state.records.forEach((element, index) => {
      if (element.id === commentId) {
        $state.records[index].replies = element.replies.filter(
          reply => reply.id !== replyId
        );
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
