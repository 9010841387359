<script>
export default {
  name: 'Card',
  props: {
    pageId: {
      type: Number,
      required: true,
    },
    postId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    handleClick(pageId, postId) {
      this.$router.push({
        name: 'posts_comments',
        params: { page_id: pageId, post_id: postId },
      });
    },
  },
};
</script>

<template>
  <div class="card cursor-pointer" @click="handleClick(pageId, postId)">
    <div class="card-body">
      <img class="card-img" :src="image" alt="Card image" />
      <h3 class="card-title">{{ title }}</h3>
      <p v-if="description" class="card-description">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card-img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.card-body {
  padding: 16px;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.card-description {
  font-size: 1rem;
  margin-bottom: 16px;
}

.card-link {
  text-decoration: none;
  color: #007bff;
}

.card-link:hover {
  text-decoration: underline;
}
</style>
