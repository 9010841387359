import { frontendURL } from 'dashboard/helper/URLHelper';
import PostsListView from './PostsList.vue';
import PagesListView from './PagesList.vue';
import PostCommentsListView from './PostCommentsList.vue';
import {
  ROLES,
  CONVERSATION_PERMISSIONS,
} from 'dashboard/constants/permissions.js';

import { FEATURE_FLAGS } from 'dashboard/featureFlags';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/facebook_pages'),
    children: [
      {
        path: '',
        name: 'facebook_pages',
        component: PagesListView,
        meta: {
          permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
          featureFlag: FEATURE_FLAGS.INBOX_VIEW,
        },
      },
      {
        path: ':page_id/posts',
        name: 'posts',
        component: PostsListView,
        meta: {
          permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
          featureFlag: FEATURE_FLAGS.INBOX_VIEW,
        },
      },
      {
        path: ':page_id/posts/:post_id',
        name: 'posts_comments',
        component: PostCommentsListView,
        meta: {
          permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
          featureFlag: FEATURE_FLAGS.INBOX_VIEW,
        },
      },
    ],
  },
];
