import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import FacebookPagesAPI from '../../api/facebookPages';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getPosts($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }, { pageId }) => {
    commit(types.default.SET_FACEBOOK_PAGE_POSTS_FETCHING_STATUS, true);
    try {
      const response = await FacebookPagesAPI.getPosts(pageId);
      commit(types.default.SET_FACEBOOK_PAGE_POSTS_FETCHING_STATUS, false);
      commit(types.default.SET_FACEBOOK_PAGE_POSTS, response.data);
    } catch (error) {
      commit(types.default.SET_FACEBOOK_PAGE_POSTS_FETCHING_STATUS, false);
    }
  },
};

export const mutations = {
  [types.default.SET_FACEBOOK_PAGE_POSTS_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_FACEBOOK_PAGE_POSTS]: MutationHelpers.set,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
