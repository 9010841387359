<script>
import { mapGetters } from 'vuex';
import { useUISettings } from 'dashboard/composables/useUISettings';
import PostCard from './components/PostCard.vue';
import RespondLayout from './RespondLayout.vue';
import BaseRespondHeader from './RespondHeader.vue';

export default {
  components: {
    RespondLayout,
    BaseRespondHeader,
    PostCard,
  },
  setup() {
    const { uiSettings } = useUISettings();

    return {
      uiSettings,
    };
  },
  computed: {
    ...mapGetters({
      postsList: 'posts/getPosts',
      uiFlags: 'posts/getUIFlags',
    }),
    posts() {
      return this.postsList;
    },
  },
  mounted() {
    this.fetchPages();
  },
  methods: {
    fetchPages() {
      this.$store.dispatch('posts/get', { pageId: this.$route.params.page_id });
    },
  },
};
</script>

<template>
  <RespondLayout
    :is-loading="uiFlags.isFetching"
    loading-message="Checking for posts..."
    :no-records-found="!postsList.length"
    no-records-message="No posts found`"
  >
    <template #header>
      <BaseRespondHeader
        header-title="Posts"
        feature-name="facebook-pages-posts"
        show-back-button="true"
      />
    </template>
    <template #body>
      <section class="flex min-w-0 p-6 overflow-auto gap-8">
        <div v-for="post in posts" :key="post.id">
          <PostCard
            :id="post.id"
            :page-id="post.channel_facebook_page_id"
            :post-id="post.id"
            :title="post.message"
            :image="post.image_path"
            :description="post.source_type"
          />
        </div>
      </section>
    </template>
  </RespondLayout>
</template>
